/* eslint-disable sonarjs/no-identical-functions */

import React, { Component, } from 'react';
import { compose } from 'recompose';
import {
  connect,
  ConnectedProps
} from 'react-redux';
import {
  ELSPropsFromToastService,
  ELSWithToastService
} from '@els/els-component-toast-react';
import {
  ELSPropsFromModalService,
  ELSWithModalService,
} from '@els/els-component-modal-react';
import {
  ELSTokenHelper,
  ELSURLHelper,
} from '@els/els-ui-common-react';
import { ELSButton } from '@els/els-component-button-react';
import { orderBy, } from 'lodash';
import moment from 'moment';
import FocusTrap from 'focus-trap-react';
import axios from 'axios';
import withHTMLHeadSEO from '../../hocs/with-html-head-seo/withHTMLHeadSEO.hoc';
import withPageLoader from '../../hocs/with-page-loader/withPageLoader.hoc';
import {
  ELSButtonSize,
  ELSButtonType
} from '../../models/button.models';
import { studySelectors } from '../../redux/student-study/studentStudy.selectors';
import { studyActions } from '../../redux/student-study/studentStudy.actions';
import { AiChatEntry } from './AiChatEntry.component';
import { ELSIcon } from '../../components/els.components';
import {
  ChatContextTypeDto,
  ChatDto,
  ChatEntitlementDto,
  ChatEntitlementTypeDto,
  ChatEntryAuthorDto,
  ChatEntryDto,
  ChatEntryEvaluationDto,
  ChatEntryTraceKey,
  ChatEntryTypeDto,
  ChatQueryIntentDto,
  ChatStatusDto,
  ChatTypeDto,
  DehydratedChatEntryDto,
} from '../../apis/florence-facade/florence-facade.dtos';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import IconWithText from '../../components/icon-with-text/IconWithText.component';
import { FEATURE_FLAG } from '../../apis/eols-features-api/eols-features-api.constants';
import { isFeatureFlag } from '../../utilities/featureFlag.utilities';
import { ServerConstants } from '../../components/app/server.constants';
import {
  ADMIN_MODAL_ID,
  ChatConnectionProtocol,
  MAX_PROMPT_LENGTH,
  MAX_PROMPT_LENGTH_DISPLAY,
  osmosisVideoUrl,
  TEMP_BOT_CHAT_ID,
  TEMP_USER_CHAT_ID
} from './ai-chat.constants';
import { AnalyticsAction } from '../../models/analytics.models';
import {
  getIsDirectAccessByFeatureFlag,
  getIsDirectAccessByIsbns,
  getLLMModel,
  getNextChatTitle,
  getNormalizedEmailDomain,
  getSortedChats,
  isAdminUser,
  toastActiveStreamingError,
  toastCreateChatError,
} from './ai-chat.utilities';
import { getNormalizedInstitution } from '../../redux/redux.utilities';
import AiChatBaseTemplate from '../../components/ai-chat-base-template/AiChatBaseTemplate.component';
import IsRender from '../../components/is-render/IsRender.component';
import PreviousChatsModal, { PreviousChatsModalId } from './PreviousChatsModal.component';
import { RoutePath } from '../../components/app/app.constants';
import { locationActions } from '../../redux/location/location.actions';
import { isInstructor } from '../../utilities/common.utilities';
import { getDefaultRequestOptions } from '../../utilities/api.utilities';
import { locationSelectors } from '../../redux/location/location.selectors';
import { AiChatUserMaterials } from './AiChatUserMaterials.component';
import AiChatAdminModal from './AiChatAdminModal.component';

const mapStateToProps = state => ({
  course: studySelectors.getCourse(state),
  userId: studySelectors.getUserId(state),
  courseSectionId: studySelectors.getCourseSectionId(state),
  messages: studySelectors.getMessages(state),
  featureFlagsGrouped: studySelectors.getFeatureFlagsGrouped(state),
  osmosisTokenDto: studySelectors.getOsmosisToken(state),
  isbns: studySelectors.getIsbns(state),
  isTestUser: studySelectors.isTestUser(state),
  userEmailDomain: studySelectors.getUserEmailDomain(state),
  roleId: studySelectors.getRoleId(state),
  evolveProducts: studySelectors.getEvolveProducts(state),
  location: locationSelectors.getLocation(state),
});

const mapDispatchToProps = {
  trackAction: studyActions.trackAction,
  fetchOsmosisTokenAction: studyActions.fetchOsmosisTokenAction,
  fetchChatEntriesAction: studyActions.fetchChatEntriesAction,
  fetchChatEvaluationsAction: studyActions.fetchChatEvaluationsAction,
  fetchChatsAction: studyActions.fetchChatsAction,
  postChatAction: studyActions.postChatAction,
  postChatEntryAction: studyActions.postChatEntryAction,
  postChatPromptAction: studyActions.postChatPromptAction,
  postChatEntryShellsAction: studyActions.postChatEntryShellsAction,
  putChatAction: studyActions.putChatAction,
  fetchAllUserCourseSectionsAction: studyActions.fetchAllUserCourseSectionsAction,
  fetchChatEntryAction: studyActions.fetchChatEntryAction,
  fetchChatEntryTracesAction: studyActions.fetchChatEntryTracesAction,
  openVitalSource: studyActions.openVitalSource,
  redirect: locationActions.redirect,
  fetchAndPostProcessChatEntryReferencesV2Action: studyActions.fetchAndPostProcessChatEntryReferencesV2Action,
  fetchChatEntitlementsAction: studyActions.fetchChatEntitlementsAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type AiChatProps = PropsFromRedux & ELSPropsFromToastService & ELSPropsFromModalService;

export type AiChatState = {
  chatEntries: ChatEntryDto[];
  chatEvaluations: ChatEntryEvaluationDto[];
  chats: ChatDto[];
  activeChatId: number;
  prompt: string;
  isLoading: boolean;
  isAnnounceLoading: boolean;
  rightBarWidth: number;
  streamStart: string;
  submitStart: string;
  promptOverride: ChatEntryDto['promptOverride'];
  searchOverride: ChatEntryDto['searchOverride'];
  userEbookIsbnsAndTitles: Record<string, string>;
  isAutoScrolling: boolean;
  isShowExplainer: boolean;
}

export class AiChatComponent extends Component<AiChatProps, AiChatState> {
  constructor(props) {
    super(props);

    this.state = {
      chatEntries: null,
      chatEvaluations: null,
      chats: null,
      activeChatId: null,
      prompt: '',
      isLoading: false,
      isAnnounceLoading: false,
      rightBarWidth: 0,
      streamStart: null,
      submitStart: null,
      promptOverride: null,
      userEbookIsbnsAndTitles: {},
      isAutoScrolling: true,
      searchOverride: null,
      isShowExplainer: true
    };

  }

  componentDidMount() {
    const {
      fetchOsmosisTokenAction,
      fetchChatsAction,
      fetchChatEntitlementsAction
    } = this.props;

    fetchOsmosisTokenAction();
    window.addEventListener('message', this.osmosisEventListener, false);

    fetchChatsAction().then((response) => {
      this.setState({
        chats: response.content,
      });
      const activeChat = this.getDefaultActiveChat(response.content);
      this.handleChatClick(activeChat, false, true);
    });

    fetchChatEntitlementsAction().then((entitlements) => {

      if (
        !entitlements
        || !entitlements[ChatEntitlementTypeDto.USER_ENTITLED]
        || !entitlements[ChatEntitlementTypeDto.USER_ENTITLED].length
      ) {
        return;
      }

      const userEbookIsbnsAndTitles = entitlements[ChatEntitlementTypeDto.USER_ENTITLED]
        .reduce((acc, entitlement: ChatEntitlementDto) => {
          return {
            ...acc,
            [entitlement.isbn]: `${entitlement.title}, ${entitlement.editionText}`
          };
        }, {});

      this.setState({ userEbookIsbnsAndTitles });
    });
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.osmosisEventListener, false);
  }

  osmosisEventListener = (e) => {
    if (e.origin !== osmosisVideoUrl) {
      return;
    }

    if (!e.data) {
      return;
    }

    if (!e.data.type) {
      return;
    }

    if (['announce-frame-initialized', 'video_start', 'video_finished'].includes(e.data.type)) {
      const { trackAction } = this.props;
      const {
        activeChatId
      } = this.state;

      trackAction({
        action: AnalyticsAction.OSMOSIS_VIDEO_PLAYER_EVENT,
        props: {
          type: e.data.type,
          activeChatId: activeChatId.toString(),
          videoId: e.data.url
        }
      });
    }
  };

  isAdminOverrideEnabled = () => {
    return isAdminUser() && ELSURLHelper.getParameterByName('adminOverride', this.props.location.search) === 'true';
  }

  isFeatureFlag = (flag: FEATURE_FLAG) => {
    const {
      featureFlagsGrouped,
      courseSectionId
    } = this.props;

    return isFeatureFlag(flag, featureFlagsGrouped, courseSectionId);
  }

  isHttpStreamingEnabled = () => {
    return this.isFeatureFlag(FEATURE_FLAG.IS_FLORENCE_HTTP_STREAMING_ENABLED);
  }

  getConnectionProtocol = (): ChatConnectionProtocol => {
    if (this.isHttpStreamingEnabled()) {
      return ChatConnectionProtocol.HTTP_STREAM;
    }
    return ChatConnectionProtocol.HTTP_POST;
  }

  trackResponseComplete = (chatEntry: ChatEntryDto) => {

    const { trackAction } = this.props;
    const {
      streamStart,
      submitStart
    } = this.state;

    let millisecondsToComplete = null;
    let millisecondsToCompleteTotal = null;

    if (streamStart && submitStart) {
      millisecondsToComplete = moment().diff(streamStart, 'milliseconds');
      millisecondsToCompleteTotal = moment().diff(submitStart, 'milliseconds');
      this.setState({
        streamStart: null,
        submitStart: null,
      });
    }

    trackAction({
      action: AnalyticsAction.AI_CHAT_BOT_RESPONSE_COMPLETE,
      props: {
        millisecondsToComplete,
        millisecondsToCompleteTotal,
        entryId: chatEntry.id,
        idx: chatEntry.index
      }
    });
  }

  handleChatScroll = () => {

    const {
      chatEntries,
      isAutoScrolling
    } = this.state;

    if (!chatEntries || chatEntries.length === 0) {
      return;
    }

    if (!isAutoScrolling) {
      return;
    }
    const element = document.getElementById('chat-scroll');
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  }

  getLastEntry = (): ChatEntryDto => {
    const {
      chatEntries,
    } = this.state;
    if (!chatEntries || !chatEntries.length) {
      return null;
    }
    const sortedEntries = this.getSortedChatEntries(chatEntries);
    return sortedEntries[sortedEntries.length - 1];
  }

  getActiveChat = (): ChatDto => {
    const {
      activeChatId,
      chats,
    } = this.state;
    if (!activeChatId || !chats || !chats.length) {
      return null;
    }
    return chats.find((chat) => {
      return chat.id === activeChatId;
    });
  }

  getActiveOrCreateChat = (): Promise<ChatDto> => {
    const activeChat = this.getActiveChat();
    if (activeChat) {
      return Promise.resolve(activeChat);
    }
    return this.handleChatCreate(
      this.getNewDefaultChat()
    );
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  buildTempUserEntry = (chat: ChatDto): ChatEntryDto => {

    const {
      prompt,
    } = this.state;

    const lastEntry = this.getLastEntry();

    return {
      id: TEMP_USER_CHAT_ID,
      chatId: chat.id,
      index: lastEntry ? lastEntry.index + 1 : 0,
      message: prompt,
      author: ChatEntryAuthorDto.USER,
      entryType: ChatEntryTypeDto.MESSAGE,
      references: [],
      queryIntent: ChatQueryIntentDto.COMMENT,
      searchFilter: null,
      traces: {
        [ChatEntryTraceKey.STUDENT_STUDY_APP]: {
          inputTokenCount: null,
          outputTokenCount: null,
          inputText: null,
          outputText: null,
          responseTime: null,
          streamingTime: null,
          version: window.getBuildVersion ? window.getBuildVersion() : null
        }
      }
    };
  }

  setTempEntriesToState = (tempEntries: ChatEntryDto[]) => {
    this.setState((prevState) => {

      const chatEntries = prevState.chatEntries ? [
        ...prevState.chatEntries,
        ...tempEntries
      ] : tempEntries;

      return {
        chatEntries
      };
    }, this.handleChatScroll);
  }

  getTempBotEntry = (parentUserEntry: ChatEntryDto, id: number): ChatEntryDto => {
    return {
      id,
      chatId: parentUserEntry.chatId,
      index: parentUserEntry.index + 1,
      message: '',
      author: ChatEntryAuthorDto.BOT,
      entryType: ChatEntryTypeDto.MESSAGE,
      references: [],
      queryIntent: ChatQueryIntentDto.COMMENT,
      searchFilter: null,
      traces: null
    };
  }

  updateChatTitle = (newEntry: ChatEntryDto, chat: ChatDto): void => {
    if (newEntry.index === 0) {
      this.handleChatUpdate({
        ...chat,
        title: newEntry.message.substring(0, 1000)
      });
    }
  }

  handlePostSubmit = () => {

    const {
      trackAction,
      postChatPromptAction,
    } = this.props;

    this.getActiveOrCreateChat()
      .then((chat) => {
        const tempUserEntry = this.buildTempUserEntry(chat);
        const tempBotEntry = this.getTempBotEntry(tempUserEntry, TEMP_BOT_CHAT_ID);
        this.setTempEntriesToState([tempUserEntry, tempBotEntry]);
        this.updateChatTitle(tempUserEntry, chat);
        return tempUserEntry;
      })
      .then((tempUserEntry) => {

        const userEntry: ChatEntryDto = { ...tempUserEntry };

        this.setState({
          streamStart: moment().toISOString(),
          prompt: '',
        });

        if (this.isAdminOverrideEnabled()) {
          if (this.state.promptOverride) {
            userEntry.promptOverride = this.state.promptOverride;
          }
          if (this.state.searchOverride) {
            userEntry.searchOverride = this.state.searchOverride;
          }
        }

        return postChatPromptAction(userEntry).then((response) => {
          trackAction({
            action: AnalyticsAction.AI_CHAT_PROMPT_SUBMITTED,
            props: {
              entryId: response.id,
              idx: response.index
            }
          });

          this.trackResponseComplete(response);
          // Brute force refresh entire chat
          this.handleChatClick(this.getActiveChat(), false, false);
          return response;
        });
      })
      .finally(() => {
        this.setState({
          isLoading: false,
          isShowExplainer: true,
          isAnnounceLoading: false
        });
      });
  }

  handleHttpStreamChunk = (message: string, botEntry: ChatEntryDto) => {
    this.setState((prevState) => {
      return {
        chatEntries: prevState.chatEntries.map((entry) => {
          if (botEntry.id === entry.id) {
            const newBotEntry = { ...entry };
            newBotEntry.message = message;
            return newBotEntry;

          }
          return entry;
        })
      };
    }, this.handleChatScroll);
  }

  httpStream = (entry: ChatEntryDto, botEntry: ChatEntryDto): Promise<string> => {

    const postBody = {
      userEntryId: entry.id,
      botEntryId: botEntry.id,
      promptOverride: null,
      searchOverride: null
    };

    if (this.isAdminOverrideEnabled()) {
      postBody.promptOverride = this.state.promptOverride;
      postBody.searchOverride = this.state.searchOverride;
    }

    if (!postBody.promptOverride) {
      delete postBody.promptOverride;
    }

    if (!postBody.searchOverride) {
      delete postBody.searchOverride;
    }

    return axios({
      url: `${ServerConstants[ServerConstants.DataSource].florenceFacadeBaseURL}/api/florence/prompt/stream`,
      method: 'POST',
      data: postBody,
      onDownloadProgress: (progressEvent) => {
        this.handleHttpStreamChunk(progressEvent.currentTarget.response, botEntry);
      },
      ...getDefaultRequestOptions({}, {})
    }).then((response) => {
      return response.data;
    });
  }

  saveAndSetShells = (userEntry: DehydratedChatEntryDto, chatId: number): Promise<ChatEntryDto[]> => {

    const {
      postChatEntryShellsAction
    } = this.props;

    if (!userEntry.message) {
      throw new Error('no user message');
    }

    return postChatEntryShellsAction(userEntry, chatId).then((response) => {
      return new Promise((resolve) => {
        this.setState((prevState) => {
          return {
            chatEntries: prevState.chatEntries
              ? [...prevState.chatEntries, ...response]
              : response
          };
        }, () => {
          this.handleChatScroll();
          resolve(response);
        });
      });
    });
  }

  handlePostProcessing = (botEntry: ChatEntryDto, attempt = 0) => {
    const {
      trackAction,
      fetchChatEntryAction,
      fetchAndPostProcessChatEntryReferencesV2Action
    } = this.props;

    if (attempt >= 5) {
      trackAction({
        action: AnalyticsAction.AI_CHAT_ERROR,
        props: {
          type: 'POST_PROCESSING_ATTEMPTS_EXCEEDED',
          entryId: botEntry.id,
          chatId: botEntry.chatId,
        }
      });
      return Promise.resolve(botEntry);
    }

    return fetchChatEntryAction(botEntry.chatId, botEntry.id).then((response) => {
      if (response.entryType === ChatEntryTypeDto.SHELL) {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(this.handlePostProcessing(botEntry, attempt + 1));
          }, 1000);
        });
      }
      return fetchAndPostProcessChatEntryReferencesV2Action(botEntry.id);
    });

  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  handleHttpStreamSubmit = () => {

    const {
      trackAction,
    } = this.props;

    this.getActiveOrCreateChat()
      .then((chat) => {
        const tempUserEntry = this.buildTempUserEntry(chat);
        const dehydratedChatEntryDto: DehydratedChatEntryDto = {
          message: tempUserEntry.message,
          traces: tempUserEntry.traces,
          searchFilter: tempUserEntry.searchFilter,
        };
        this.updateChatTitle(tempUserEntry, chat);
        return this.saveAndSetShells(dehydratedChatEntryDto, chat.id);
      })
      .then((response) => {
        const userEntry = response[0];
        const botEntry = response[1];
        trackAction({
          action: AnalyticsAction.AI_CHAT_PROMPT_SUBMITTED,
          props: {
            entryId: userEntry.id,
            idx: userEntry.index
          }
        });

        this.setState({
          prompt: '',
          streamStart: moment().toISOString()
        });

        return this.httpStream(userEntry, botEntry)
          .then(() => {
            this.setState((prevState) => {
              return {
                chatEntries: prevState.chatEntries.map((entry: ChatEntryDto) => {
                  if (entry.id === botEntry.id) {
                    return {
                      ...entry,
                      entryType: ChatEntryTypeDto.MESSAGE, // Changing this enables the feedback buttons
                      _isPostProcessing: true // This is FE only prop used to show the loading spinner on citations
                    };
                  }
                  return entry;
                })
              };
            }, this.handleChatScroll);
            return this.handlePostProcessing(botEntry);
          })
          .then((postProcessedEntry: ChatEntryDto) => {

            if (postProcessedEntry.errorMessage) {
              trackAction({
                action: AnalyticsAction.AI_CHAT_ERROR,
                props: {
                  type: postProcessedEntry.errorMessage,
                  entryId: postProcessedEntry.id,
                  chatId: postProcessedEntry.chatId,
                }
              });
            }

            this.setState((prevState) => {
              return {
                chatEntries: prevState.chatEntries.map((entry) => {
                  if (entry.id === botEntry.id) {
                    return postProcessedEntry.entryType === ChatEntryTypeDto.SHELL ? {
                      ...entry,
                      _isPostProcessing: false
                    } : postProcessedEntry;
                  }
                  return entry;
                })
              };
            }, this.handleChatScroll);
            this.trackResponseComplete(botEntry);
          });
      })
      .finally(() => {
        this.setState({
          isLoading: false,
          isShowExplainer: true,
          isAnnounceLoading: false
        });
      });
  }

  handleChatSubmit = () => {

    const {
      trackAction,
      toastService
    } = this.props;

    const {
      isLoading
    } = this.state;

    const protocol = this.getConnectionProtocol();

    if (isLoading) {
      toastActiveStreamingError(toastService, trackAction, `SUBMIT_${protocol}`);
      return;
    }

    this.setState(() => {
      return {
        isLoading: true,
        submitStart: moment().toISOString(),
        isAutoScrolling: true
      };
    }, this.handleChatScroll);

    // Delaying isAnnounceLoading to prevent interruption of the aria-live announcement
    setTimeout(() => {
      this.setState({
        isAnnounceLoading: true
      });
    }, 200);

    if (protocol === ChatConnectionProtocol.HTTP_STREAM) {
      this.handleHttpStreamSubmit();
      return;
    }
    this.handlePostSubmit();
  }

  handlePromptChange = (e) => {
    this.setState({
      prompt: e.target.value
    });
  }

  handleWheel = () => {
    this.setState({
      isAutoScrolling: false
    });
  }

  handlePromptSend = (e) => {
    if (!this.state.prompt || this.state.prompt.trim() === '') {
      return;
    }
    if ((e.key === 'Enter' && !e.shiftKey) || e.type === 'click') {
      e.preventDefault();
      this.handleChatSubmit();
    }
  }

  handlePromptClickStop = (e) => {
    e.preventDefault();
  }
  getNewDefaultChat = (): Omit<ChatDto, 'id'> => {

    const {
      courseSectionId,
      userId,
      featureFlagsGrouped,
      isTestUser,
      isbns,
      userEmailDomain,
      roleId,
      evolveProducts
    } = this.props;

    const {
      chats
    } = this.state;

    return {
      createdAt: moment().toISOString(),
      updatedAt: moment().toISOString(),
      title: getNextChatTitle('Chat', chats),
      userId: parseInt(userId, 10),
      contextId: courseSectionId,
      contextType: ChatContextTypeDto.COURSE_SECTION_ID,
      status: ChatStatusDto.ACTIVE,
      entries: null,
      type: ChatTypeDto.FLORENCE_USER_CHAT,
      modelName: getLLMModel(featureFlagsGrouped, courseSectionId),
      attributes: {
        isTestUser,
        isMasqueradeUser: ELSTokenHelper.isMasqueradeUser(),
        isDirectAccessByFeatureFlag: getIsDirectAccessByFeatureFlag(featureFlagsGrouped, courseSectionId, isbns),
        isDirectAccessByIsbn: getIsDirectAccessByIsbns(evolveProducts, isbns),
        institutionNormalized: getNormalizedInstitution(userEmailDomain),
        emailDomain: getNormalizedEmailDomain(userEmailDomain),
        userRole: roleId
      }
    };
  }

  handleChatCreate = (chat: Omit<ChatDto, 'id'>) => {
    const {
      postChatAction,
    } = this.props;
    return postChatAction(chat).then((response) => {
      this.setState((prevState) => {
        return {
          activeChatId: response.id,
          chats: prevState.chats ? [
            ...prevState.chats,
            response
          ] : [response],
          chatEntries: [],
        };
      });
      return response;
    });
  }

  handleChatUpdate = (chat: ChatDto) => {
    const {
      putChatAction
    } = this.props;
    return putChatAction(chat).then((response) => {
      this.setState((prevState) => {
        return {
          chats: prevState.chats ? prevState.chats.map((_chat) => {
            if (_chat.id === chat.id) {
              return response;
            }
            return _chat;
          }) : prevState.chats
        };
      });
      return response;
    });
  }

  handleEditChatTitle = (chat: ChatDto) => {
    const {
      trackAction,
      toastService
    } = this.props;

    const {
      activeChatId,
      isLoading
    } = this.state;

    if (isLoading) {
      toastActiveStreamingError(toastService, trackAction, 'CHAT_EDIT_CLICK');
      return;
    }

    trackAction({
      action: AnalyticsAction.AI_CHAT_EDIT_CONFIRM_CLICK,
      props: {
        targetChatId: chat.id,
        targetChatTitle: chat.title,
        isTargetActive: chat.id === activeChatId,
        isStreaming: isLoading
      }
    });

    this.handleChatUpdate({
      ...chat,
    });
  }

  handleNewChatClick = () => {
    const {
      trackAction,
      toastService
    } = this.props;

    const {
      isLoading,
      chatEntries
    } = this.state;

    trackAction({
      action: AnalyticsAction.AI_CHAT_NEW_CHAT_CLICK,
      props: {
        isStreaming: isLoading
      }
    });

    if (isLoading) {
      toastActiveStreamingError(toastService, trackAction, 'NEW_CHAT_CLICK');
      return;
    }

    if (!chatEntries || !chatEntries.length) {
      toastCreateChatError(toastService);
      return;
    }

    this.handleChatCreate(this.getNewDefaultChat());
  }

  handleChatClick = (
    chat: ChatDto,
    sendClickEvent: boolean,
    checkLoading: boolean
  ) => {
    if (!chat) {
      return;
    }

    const {
      trackAction,
      fetchChatEvaluationsAction,
      fetchChatEntriesAction,
      toastService,
      modalService
    } = this.props;

    const {
      activeChatId,
      isLoading
    } = this.state;

    if (sendClickEvent) {
      trackAction({
        action: AnalyticsAction.AI_CHAT_CHAT_CLICK,
        props: {
          targetChatId: chat.id,
          targetChatTitle: chat.title,
          isTargetActive: chat.id === activeChatId,
          isStreaming: isLoading
        }
      });
    }

    if (checkLoading && isLoading) {
      toastActiveStreamingError(toastService, trackAction, 'CHAT_CLICK');
      return;
    }

    fetchChatEvaluationsAction(chat.id.toString()).then((response) => {
      this.setState({
        chatEvaluations: response,
      });
    });
    fetchChatEntriesAction(chat.id.toString()).then((response) => {
      this.setState({
        chatEntries: response.content,
        activeChatId: chat.id,
      }, () => {
        this.handleChatScroll();
        modalService.closeModal(PreviousChatsModalId);
      });
    });
  }

  getDefaultActiveChat = (chats: ChatDto[]): ChatDto => {
    if (!chats) {
      return null;
    }
    return getSortedChats(chats).filter((chat) => {
      // Default to existing chat if has emailDomain
      return chat.attributes && chat.attributes.emailDomain;
    })[0];
  }

  getSortedChatEntries = (chatEntries: ChatEntryDto[]): ChatEntryDto[] => {
    if (!chatEntries) {
      return null;
    }

    return orderBy(chatEntries, ['id', 'index'], 'asc');
  }

  getEntryEvaluation = (entry: ChatEntryDto) => {
    if (!entry) {
      return null;
    }
    const {
      chatEvaluations
    } = this.state;
    if (!chatEvaluations || !chatEvaluations.length) {
      return null;
    }
    return chatEvaluations.find((item) => {
      return item.entryId === entry.id;
    });
  }

  onEntryEvaluationSave = (entryEvaluation: ChatEntryEvaluationDto) => {
    this.setState((prevState) => {
      if (!prevState.chatEvaluations || !prevState.chatEvaluations.length) {
        return {
          chatEvaluations: [
            entryEvaluation
          ]
        };
      }
      const existingRecord = prevState.chatEvaluations.find((item) => {
        return item.id === entryEvaluation.id;
      });
      if (existingRecord) {
        return {
          chatEvaluations: prevState.chatEvaluations.map((item) => {
            if (item.id === entryEvaluation.id) {
              return entryEvaluation;
            }
            return item;
          })
        };
      }
      return {
        chatEvaluations: [
          ...prevState.chatEvaluations,
          entryEvaluation
        ]
      };
    }, () => {
      this.slideRightBar(0);
    });
  }

  slideRightBar = (target: number) => {
    if (this.state.rightBarWidth === target) {
      return;
    }
    this.setState((prevState) => {
      const increment = target > prevState.rightBarWidth ? 2 : -2;
      return {
        rightBarWidth: prevState.rightBarWidth + increment
      };
    }, () => {
      setTimeout(() => {
        this.slideRightBar(target);
      }, 1);
    });
  }

  isLastItem = (entry: ChatEntryDto): boolean => {
    const {
      chatEntries
    } = this.state;
    if (!chatEntries || !chatEntries.length) {
      return false;
    }
    const orderedChats = this.getSortedChatEntries(chatEntries);
    return orderedChats[orderedChats.length - 1].id === entry.id;
  }

  handlePreviewChatsClick = () => {

    const {
      modalService,
      messages,
      toastService,
      trackAction
    } = this.props;

    const {
      activeChatId,
      chats,
      isLoading
    } = this.state;

    if (isLoading) {
      toastActiveStreamingError(toastService, trackAction, 'PREVIOUS_CHATS_CLICK');
      return;
    }

    modalService.openCustomModal({
      dialogAriaLabel: 'Previous chats',
      modalId: PreviousChatsModalId,
      modal: (
        <FocusTrap>
          <PreviousChatsModal
            chats={chats}
            activeChatId={activeChatId}
            messages={messages}
            handleChatClick={this.handleChatClick}
            handleEditChatTitle={this.handleEditChatTitle}
            modalService={modalService}
            trackAction={trackAction}
            toastService={toastService}
          />
        </FocusTrap>
      )
    });
  }

  handleHowItWorksClick = () => {
    this.props.trackAction({
      action: AnalyticsAction.AI_CHAT_HOW_IT_WORKS_LINK_CLICK,
      props: null
    });
  }

  handleExceedLimitAttempt = (): boolean => {
    return this.state.prompt.length === MAX_PROMPT_LENGTH;
  }

  handlePerformanceClick = () => {
    const {
      redirect,
      trackAction,
    } = this.props;

    const targetPage = RoutePath.AI_CHAT_PERFORMANCE;

    trackAction({
      action: AnalyticsAction.AI_CHAT_TOP_NAV_CLICK,
      props: {
        targetPage
      }
    });

    redirect(targetPage);
  };

  isShowPerformanceNav = () => {
    const {
      roleId,
      featureFlagsGrouped,
      courseSectionId
    } = this.props;
    return isInstructor(roleId) && isFeatureFlag(
      FEATURE_FLAG.IS_AI_CHAT_INSTRUCTOR_PERFORMANCE_ENABLED,
      featureFlagsGrouped,
      courseSectionId
    );
  };

  handleSaveOverride = (
    promptOverride: ChatEntryDto['promptOverride'],
    searchOverride: ChatEntryDto['searchOverride']
  ) => {
    const {
      modalService,
    } = this.props;
    this.setState({ promptOverride, searchOverride });
    modalService.closeModal(ADMIN_MODAL_ID);
  }

  handleOpenAdminModal = () => {
    const {
      modalService,
    } = this.props;
    modalService.openCustomModal({
      modalId: ADMIN_MODAL_ID,
      modal: (
        <AiChatAdminModal
          promptOverride={this.state.promptOverride}
          searchOverride={this.state.searchOverride}
          handleSave={this.handleSaveOverride}
          handleCancel={() => modalService.closeModal(ADMIN_MODAL_ID)}
        />
      )
    });
  }

  renderTopBar = () => {

    const isHowItWorksLinkEnabled = this.isFeatureFlag(FEATURE_FLAG.IS_FLORENCE_HOW_IT_WORKS_LINK_ENABLED);

    return (
      <div className="c-els-ai-chat__title">

        <FlexLayout modifiers={[
          FlexLayoutModifier.GUTTERS_2X,
          FlexLayoutModifier.MIDDLE
        ]}>
          <FlexItem modifiers={[
            FlexLayoutModifier.GROW,
          ]}>
            <h1
              id="chat-page-header"
            >
              Chat
            </h1>
          </FlexItem>
          <FlexItem>

            <FlexLayout modifiers={[
              FlexLayoutModifier.GUTTERS_2X,
              FlexLayoutModifier.GUTTERS_1X_MOBILE,
              FlexLayoutModifier.RIGHT,
              FlexLayoutModifier.MIDDLE
            ]}>
              <FlexItem>
                <button
                  type="button"
                  id="prev-chats-btn"
                  className="u-els-debuttonize u-els-anchorize"
                  onClick={this.handlePreviewChatsClick}
                >
                  Previous chats
                </button>
              </FlexItem>
              <FlexItem isRender={isHowItWorksLinkEnabled}>
                <button
                  type="button"
                  className="u-els-debuttonize u-els-anchorize"
                  id="how-it-works-btn"
                  onClick={this.handleHowItWorksClick}
                >
                  How it works
                </button>
              </FlexItem>
              <FlexItem isRender={this.isShowPerformanceNav()}>
                <button
                  type="button"
                  id="ins-insights-nav-btn"
                  className="u-els-debuttonize u-els-anchorize"
                  onClick={this.handlePerformanceClick}
                >
                  Instructor insights
                </button>
              </FlexItem>
            </FlexLayout>

          </FlexItem>
        </FlexLayout>

      </div>
    );

  }

  isMobileInputHeight = (): boolean => {
    const { innerWidth, innerHeight, screen } = window;
    if (!screen || !screen.orientation || !screen.orientation.type) {
      return false;
    }
    const { type } = screen.orientation;
    const isTouchScreen = navigator.maxTouchPoints > 0;
    return (isTouchScreen && ((innerWidth <= 600 && type.includes('portrait'))
      || (innerHeight <= 600 && type.includes('landscape'))));
  }

  getLiveAnnouncement = (): string => {
    const {
      isAnnounceLoading
    } = this.state;

    if (isAnnounceLoading) {
      return 'Sherpath AI is generating its response';
    }

    const lastEntry = this.getLastEntry();
    if (lastEntry
      && lastEntry.author === 'BOT'
      && lastEntry.message) {
      return `Sherpath AI responded: ${lastEntry.message}`;
    }
    return 'An error occurred while generating the response';
  }

  getIsShowExplainer = () => {
    const isChatBotExplainerEnabled = this.isFeatureFlag(FEATURE_FLAG.IS_CHATBOT_EXPLAINER_ENABLED);

    if (!isChatBotExplainerEnabled) {
      return false;
    }

    const {
      chatEntries,
      isLoading,
      isShowExplainer
    } = this.state;

    if (!isShowExplainer) {
      return false;
    }

    if (isLoading) {
      return false;
    }

    return chatEntries && !chatEntries.length;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  render() {

    const {
      chatEntries,
      prompt,
      isLoading,
      userEbookIsbnsAndTitles
    } = this.state;

    const {
      toastService,
      modalService,
      userId,
      trackAction,
      osmosisTokenDto,
      featureFlagsGrouped,
      courseSectionId,
      isbns,
      fetchChatEntryTracesAction,
      openVitalSource,
      messages,
      evolveProducts,
    } = this.props;

    const isDirectAccessByFeatureFlag = getIsDirectAccessByFeatureFlag(featureFlagsGrouped, courseSectionId, isbns);
    const isDirectAccessByIsbn = getIsDirectAccessByIsbns(evolveProducts, isbns);
    const isDirectAccess = isDirectAccessByFeatureFlag || isDirectAccessByIsbn;
    const isMobile = this.isMobileInputHeight();
    const isStopStreamingEnabled = this.isFeatureFlag(FEATURE_FLAG.IS_FLORENCE_STOP_STREAMING_ENABLED);
    const isChatBotMaterialsEnabled = this.isFeatureFlag(FEATURE_FLAG.IS_CHATBOT_MATERIALS_LIBRARY_ENABLED);

    return (
      <AiChatBaseTemplate>
        <div className="c-els-ai-chat">
          <div className="c-els-ai-chat__top">
            <div className="c-els-ai-chat__width">
              {this.renderTopBar()}
            </div>
          </div>
          <div className="c-els-ai-chat__center">
            <div className="c-els-ai-chat__width">

              <div className="c-els-ai-chat__entries" id="chat-scroll" onWheel={this.handleWheel}>

                {
                  this.getIsShowExplainer() && (
                    <div className="c-els-ai-chat__explainer">

                      <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS]}>
                        <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                          <h3>Welcome to Sherpath AI!</h3>
                        </FlexItem>
                        <FlexItem>
                          <button
                            className="u-els-debuttonize u-els-anchorize"
                            type="button"
                            onClick={() => {
                              this.setState({
                                isShowExplainer: false
                              });
                            }}
                          >
                            <ELSIcon
                              name="close"
                              size="1x"
                            />
                            <span className="u-els-hide-visually">Close</span>
                          </button>
                        </FlexItem>
                      </FlexLayout>

                      <p className="o-els-container">
                        Sherpath AI is a conversational tool powered by generative AI and designed to support students and educators
                        by delivering evidence-based responses to healthcare-related questions.
                        Here are some pointers to get you started!
                      </p>

                      <div className="o-els-container o-els-container--1o2">Sherpath AI</div>
                      <ul className="c-els-list">
                        <li className="c-els-list__item">Searches your textbooks using the topics in your message</li>
                        <li className="c-els-list__item">Cannot search by chapter or section keywords</li>
                        <li className="c-els-list__item">Does not have access to your course assignments or other materials</li>
                      </ul>

                      <div className="o-els-container o-els-container--1x1o2">
                        <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS_1o2, FlexLayoutModifier.MIDDLE]}>
                          <FlexItem>
                            <ELSIcon
                              size="1x1o4"
                              prefix="hmds"
                              name="checkmark"
                              customClass="u-els-color-confirm u-els-display-block"
                            />
                          </FlexItem>
                          <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                            <h4 className="">Sherpath AI <strong>can</strong> answer:</h4>
                          </FlexItem>
                        </FlexLayout>
                        <div className="c-els-ai-chat__explainer-examples">
                          <p className="o-els-container o-els-container--1o2">&quot;What are the signs and symptoms of congestive heart failure?&quot;</p>
                          <p>&quot;How do beta blockers work to manage hypertension?&quot;</p>
                        </div>
                      </div>

                      <div className="o-els-container o-els-container--1x1o2">
                        <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS_1o2, FlexLayoutModifier.MIDDLE]}>
                          <FlexItem>
                            <ELSIcon
                              size="1x1o4"
                              prefix="hmds"
                              name="close"
                              customClass="u-els-color-warn u-els-display-block"
                            />
                          </FlexItem>
                          <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                            <h4>Sherpath AI <strong>cannot</strong> answer:</h4>
                          </FlexItem>
                        </FlexLayout>
                        <div className="c-els-ai-chat__explainer-examples">
                          <p className="o-els-container o-els-container--1o2">&quot;What is in chapter 2?&quot;</p>
                          <p>&quot;When is my next assignment due?&quot;</p>
                        </div>
                      </div>

                      <p>Ask away and happy learning!</p>
                    </div>
                  )
                }

                {
                  chatEntries && (
                    this.getSortedChatEntries(chatEntries).map((chatEntry) => {
                      return (
                        <AiChatEntry
                          userId={userId}
                          key={chatEntry.id}
                          entry={chatEntry}
                          onFeedbackSaveClick={this.onEntryEvaluationSave}
                          entryEvaluation={this.getEntryEvaluation(chatEntry)}
                          toastService={toastService}
                          modalService={modalService}
                          trackAction={trackAction}
                          osmosisTokenDto={osmosisTokenDto}
                          isLastItem={this.isLastItem(chatEntry)}
                          isCitationLinkEnabled={this.isFeatureFlag(FEATURE_FLAG.IS_BOOK_CITATION_LINK_ENABLED)}
                          isAdminFeatureOn={this.isAdminOverrideEnabled()}
                          fetchChatEntryTracesAction={fetchChatEntryTracesAction}
                          isDirectAccessByFeatureFlag={isDirectAccessByFeatureFlag}
                          isDirectAccessByIsbn={isDirectAccessByIsbn}
                          handleChatScroll={this.handleChatScroll}
                          entitlements={userEbookIsbnsAndTitles}
                        />
                      );
                    })
                  )
                }
              </div>
            </div>
          </div>
          <div className="c-els-ai-chat__bottom">
            <div className="c-els-ai-chat__width">
              <div className="c-els-ai-chat__prompt">
                {
                  this.isAdminOverrideEnabled() && (
                    <div>
                      <button
                        className="u-els-anchorize"
                        type="button"
                        onClick={this.handleOpenAdminModal}
                      >
                        <IconWithText
                          iconName="edit"
                          iconPrefix="hmds"
                        >
                          Prompt override
                        </IconWithText>
                      </button>
                    </div>
                  )
                }
                <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS]}>
                  <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                    <div className="c-els-field">
                      <label htmlFor="chat-prompt-input">
                        <span className="u-els-hide-visually">Ask a question and get an answer from your materials</span>
                        <textarea
                          className="c-els-field__input"
                          id="chat-prompt-input"
                          value={prompt}
                          onKeyPress={this.handlePromptSend}
                          onChange={this.handlePromptChange}
                          placeholder={`Ask a question & get an answer from your materials (${MAX_PROMPT_LENGTH_DISPLAY} characters max)`}
                          maxLength={MAX_PROMPT_LENGTH}
                          rows={isMobile ? 2 : 5}
                        />
                      </label>
                    </div>
                  </FlexItem>
                  <FlexItem>
                    <ELSButton
                      className="c-els-ai-chat__submit-btn"
                      id="chat-submit-btn"
                      type={ELSButtonType.SECONDARY}
                      size={ELSButtonSize.DEFAULT}
                      onClick={(e) => {
                        if (isStopStreamingEnabled && isLoading) {
                          this.handlePromptClickStop(e);
                          return;
                        }
                        this.handlePromptSend(e);
                      }}
                    >
                      <IsRender isRender={isStopStreamingEnabled && isLoading}>
                        Stop
                        <span className="u-els-hide-visually">Stop chat submission</span>
                      </IsRender>
                      <IsRender isRender={!isStopStreamingEnabled || !isLoading}>
                        <ELSIcon
                          name="send"
                          prefix="gizmo"
                          size="1x1o2"
                        />
                        <span className="u-els-hide-visually">Submit chat</span>
                      </IsRender>
                    </ELSButton>
                  </FlexItem>
                </FlexLayout>

                <div className="u-els-margin-top-1o2 u-els-color-n9">
                  <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS]}>
                    <FlexItem>
                      {isChatBotMaterialsEnabled && !isDirectAccess
                        && (
                          <AiChatUserMaterials ebookIsbnsAndTitles={userEbookIsbnsAndTitles}
                                               openVitalSource={openVitalSource}
                                               messages={messages} />
                        )}
                    </FlexItem>
                    <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                      <FlexLayout isRender={this.handleExceedLimitAttempt()}
                                  modifiers={[FlexLayoutModifier.GUTTERS_1o2, FlexLayoutModifier.RIGHT, FlexLayoutModifier.MIDDLE]}>
                        <FlexItem>
                          <ELSIcon
                            name="flag"
                            prefix="gizmo"
                            size="1x"
                            customClass="u-els-display-block"
                          />
                        </FlexItem>
                        <FlexItem>
                          We cannot exceed {MAX_PROMPT_LENGTH_DISPLAY} characters
                        </FlexItem>
                      </FlexLayout>
                    </FlexItem>
                    <FlexItem>
                      <button
                        className="u-els-debuttonize u-els-anchorize"
                        id="new-chat-btn"
                        type="button"
                        onClick={this.handleNewChatClick}
                      >
                        <FlexLayout>
                          <FlexItem>
                            <IconWithText
                              iconName="plus"
                              iconPrefix="gizmo"
                            >
                              New Chat
                            </IconWithText>
                          </FlexItem>
                        </FlexLayout>
                      </button>
                    </FlexItem>
                  </FlexLayout>
                </div>
              </div>
            </div>
          </div>
          <span className="u-els-hide-visually" aria-live="assertive">
            {this.getLiveAnnouncement()}
          </span>
        </div>
      </AiChatBaseTemplate>
    );
  }
}

const
  enhancers = [
    connector,
    ELSWithToastService,
    ELSWithModalService,
    withHTMLHeadSEO({ title: 'Sherpath AI' }),
    withPageLoader // This must come after connect
  ];

const AiChat = compose(...enhancers)(AiChatComponent);
export default AiChat;
